import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Hooks
import useTranslation from "../hooks/useTranslation"

// Icons
import SubscriptionIcon from "../assets/icons/module-subscription.svg"
import RefundIcon from "../assets/icons/module-refund.svg"
import CancelSubscriptionIcon from "../assets/icons/module-cancel-subscription.svg"
import WordpressLogo from "../assets/icons/wordpress.svg"
import GithubLogo from "../assets/icons/github.svg"

// Shapes
import TwistedShapesLeft from "../assets/modules-twisted-shapes-left.svg"
import TwistedShapesRight from "../assets/modules-twisted-shapes-right.svg"
import TwistedShapesResponsive from "../assets/module-page-twisted-shape-responsive.svg"

// Components
import FAQ from "../components/FAQ-inpage"
import ModulePageLayout from "../components/module-page-layout"

// Styles
import "../styles/templates/module-page.scss"

const HeroSection = ({ titleColor, subtitle, logo, MODULE }) => {
  const { formatMessage } = useIntl()

  return (
    <section className="section is-hero has-margin-bottom-7 has-margin-bottom-5-mobile has-margin-top-6 has-margin-top-0-mobile">
      <div className="container">
        <div className="columns">
          <div className="column is-10">
            <div className="logo-wrapper">
              <img src={logo} alt="Logo" />
            </div>
            <h1
              style={{ color: titleColor }}
              className="title has-margin-bottom-4 is-spaced "
            >
              {formatMessage(
                { id: "payment-modules_module_hero-title" },
                { MODULE }
              )}
            </h1>
            <p className="subtitle is-size-6-mobile has-margin-bottom-4-mobile">
              {subtitle}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const Section2 = ({ available_features }) => {
  const { t } = useTranslation()
  const items = [
    {
      icon: <SubscriptionIcon />,
      id: "subscriptions",
      title: t("payment-modules_module_section2_item1_title"),
      subtitle: t("payment-modules_module_section2_item1_subtitle"),
    },
    {
      icon: <CancelSubscriptionIcon />,
      id: "refund",
      title: t("payment-modules_module_section2_item2_title"),
      subtitle: t("payment-modules_module_section2_item2_subtitle"),
    },
    {
      icon: <RefundIcon />,
      id: "cancel",
      title: t("payment-modules_module_section2_item3_title"),
      subtitle: t("payment-modules_module_section2_item3_subtitle"),
    },
  ]
  if (!available_features) return <></>
  return (
    <section className="section section-2 has-margin-bottom-5-mobile">
      <div className="container">
        <h3 className="title main-title">
          {t("payment-modules_module_section2-title")}
        </h3>
        <div className="columns is-variable is-8">
          {items.map(item =>
            available_features.indexOf(item.id) !== -1 ? (
              <div
                key={item.id}
                className="column item-wrapper is-one-third has-margin-bottom-5-mobile"
              >
                <span style={{ width: 64, height: 64 }} className="icon">
                  {item.icon}
                </span>
                <h2 className="title is-spaced has-margin-bottom-0">
                  {item.title}
                </h2>
                <p className="subtitle is-6">{item.subtitle}</p>
              </div>
            ) : (
              <></>
            )
          )}
        </div>
      </div>
    </section>
  )
}

// const Section3 = ({ data }) => {
//   const { t } = useTranslation()
//   const items = [
//     {
//       title: "LAST UPDATED",
//       subtitle: data.last_updated,
//     },
//     {
//       title: "MODULE VERSION",
//       subtitle: data.module_version,
//     },
//     {
//       title: "PLATFORM VERSION",
//       subtitle: data.platform_version,
//     },
//     {
//       title: "MINIMUM VERSION",
//       subtitle: data.min_version,
//     },
//     {
//       title: "MAXIMUM VERSION",
//       subtitle: data.max_version,
//     },
//     {
//       title: "MARKETPLACE",
//       subtitle: data.marketplace,
//     },
//   ]
//   return (
//     <section className="section section-3">
//       <div className="container">
//         <div className="columns items-wrapper">
//           {items.map(item => (
//             <div key={item.title} className="column item-wrapper">
//               <h2 className="title is-spaced has-margin-bottom-0">
//                 {item.title}
//               </h2>
//               <p className="subtitle is-6">{item.subtitle}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   )
// }

const Section3 = ({ steps, github }) => {
  const { t } = useTranslation()
  const sources = [
    // {
    //   name: "wordpress",
    //   url: "https://www.wordpress.com",
    //   description:
    //     "Download the plugin and install it from the WordPress Plugin Directory",
    // },
    {
      name: "github",
      url: github,
      description: t("payment-modules_module_section3-source1-description"),
    },
  ]
  const logos = {
    wordpress: <WordpressLogo />,
    github: <GithubLogo />,
  }
  const [selectedMenu, setSelectedMenu] = useState(Object.keys(steps)[0])
  return (
    <section className="section section-4">
      <div className="container">
        <h3 className="title">{t("payment-modules_module_section3-title")}</h3>
        {steps.manually ? (
          <ul className="menu-wrapper is-flex has-margin-bottom-5">
            {Object.keys(steps).map((item, i) => (
              <li
                key={item}
                className={`${
                  i !== Object.keys(steps).length - 1
                    ? "has-margin-right-5"
                    : ""
                }`}
              >
                <button
                  onClick={() => setSelectedMenu(item)}
                  onKeyPress={() => setSelectedMenu(item)}
                  className={item === selectedMenu ? "is-selected" : ""}
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
        <ol className="steps-wrapper">
          {steps[selectedMenu].map(step => (
            <li key={step} dangerouslySetInnerHTML={{ __html: step }} />
          ))}
        </ol>
        {sources.map(source => (
          <div
            key={source.name}
            className="is-card is-flex is-vcentered is-hspaced"
          >
            <div className="is-flex">
              <span className="icon">{logos[source.name]}</span>
              <p>{source.description}</p>
            </div>
            <a
              href={source.url}
              className="button is-primary is-rounded"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </div>
        ))}
      </div>
    </section>
  )
}

const Section5 = ({ MODULE }) => {
  const { t } = useTranslation()
  const { formatMessage } = useIntl()
  const items = [
    {
      title: t("payment-modules_module_faq_Q1"),
      answer: t("payment-modules_module_faq_A1"),
    },
    {
      title: t("payment-modules_module_faq_Q2"),
      answer: t("payment-modules_module_faq_A2"),
    },
    {
      title: formatMessage({ id: "payment-modules_module_faq_Q3" }, { MODULE }),
      answer: formatMessage(
        { id: "payment-modules_module_faq_A3" },
        { MODULE }
      ),
    },
    {
      title: formatMessage({ id: "payment-modules_module_faq_Q4" }, { MODULE }),
      answer: formatMessage(
        { id: "payment-modules_module_faq_A4" },
        { MODULE }
      ),
    },
    {
      title: formatMessage({ id: "payment-modules_module_faq_Q5" }, { MODULE }),
      answer: formatMessage(
        { id: "payment-modules_module_faq_A5" },
        { MODULE }
      ),
    },
  ]
  const [selectedItem, setSelectedItem] = useState(items[0])
  return (
    <section className="section section-5">
      <div className="container">
        <h3 className="title is-spaced main-title has-text-centered">
          {t("Frequently Asked Questions")}
        </h3>
        <FAQ
          items={items}
          hasInlineAnswer
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </div>
    </section>
  )
}

export default function Template({ data }) {
  const { t } = useTranslation()
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const {
    logo,
    module,
    // hero_title,
    hero_title_color,
    hero_subtitle,
    shapes_hue_rotate,
    stripe_color,
    // last_updated,
    available_features,
    // module_version,
    // platform_version,
    // min_version,
    // max_version,
    // marketplace,
    how_to_install,
    github,
  } = frontmatter
  const [widgetVisible, setWidgetVisible] = useState(true)
  const section5Ref = useRef()
  useScrollPosition(
    ({ currPos: { y } }) => {
      if (y < 80) setWidgetVisible(false)
      else setWidgetVisible(true)
    },
    [],
    section5Ref
  )
  return (
    <Layout
      stripeColor={stripe_color}
      shapes_hue_rotate={shapes_hue_rotate}
      pageName="module-page"
      TwistedShapesLeft={TwistedShapesLeft}
      TwistedShapesRight={TwistedShapesRight}
      TwistedShapesResponsive={TwistedShapesResponsive}
      hasLightBackground
      noMarginTopFooter
    >
      <SEO title={`${module} | ${t("eCommerce Payment Modules")}`} />
      <ModulePageLayout widgetVisible={widgetVisible}>
        <HeroSection
          subtitle={hero_subtitle}
          logo={logo.publicURL}
          titleColor={hero_title_color}
          MODULE={module}
        />
        <Section2 available_features={available_features} />
        {/* <Section3
          data={{
            last_updated,
            module_version,
            platform_version,
            min_version,
            max_version,
            marketplace,
          }}
        /> */}
        <Section3 steps={how_to_install} github={github} />
        <div style={{ marginBottom: 120 }} ref={section5Ref}>
          <Section5 MODULE={module} />
        </div>
      </ModulePageLayout>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($originalPath: String!, $locale: String!) {
    markdownRemark(
      frontmatter: { path: { eq: $originalPath }, locale: { eq: $locale } }
    ) {
      html
      frontmatter {
        path
        logo {
          publicURL
        }
        module
        hero_title_color
        hero_subtitle
        available_features
        shapes_hue_rotate
        stripe_color
        last_updated
        how_to_install {
          automatic
          manually
        }
        github
      }
    }
  }
`
